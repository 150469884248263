import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    history:"History",
    historyView:'Zijiang has gone through three decades of growth since its inception.',
    historyBtn:'More',
    home: 'Home',
    about: 'About',
    aboutbtn:'More',
    aboutView:'Zijiang, founded in 1981, is a private investment holding group rooted in shanghai.<br />Zijiang is named after the river Zigang near its place of origin in the hope that it will gather strength and expand space for sustained development.',
    contact: 'Contact Us',
    buju:'Business Segments',
    bujuView:'Zijiang has two core segments: manufacturing and services.',
    jianjie:'About Zijiang',
    jiazhi:'Core Values',
    licheng:'History',
    rencai:'Talent Strategy',
    shehui:'Social Responsibility',
    dang:'Party, League and Trade Union',
    baozhuang:'Soft Packaging and New Materials',
    ruanbao:'Trade Logistics',
    qita:'Other Manufacturing Segments',
    yanfa:'R & D Base',
    daxue:'University Campus',
    peitao:'Supporting Area',
    chuangxin:'Innovation Investment',
    zhuzai:'Residential Development',
    jiudian:'Hotels',
    bangong:'Office Buildings',
    guanyu:'About Zijiang',
    zhizhao:'Manufacturing',
    qitalei:'-High-Tech Zone',
    dichanye:'-Real Estate',
     icp:'Copyright 2010 All rights reserved by Zijiang Holding    沪ICP备案号11028486号-1 ',
     dizhi:'No.1388, qixin road, minhang district',
     name: "Name",
     email: "Email",
     mobile: "Phone Number",
     content: "Please leave your comments*"
    // 其他英文翻译
  },
  zh: {
    history:"发展历程",
    historyView:'从创立至今，紫江已走过四十多年的创业历程。',
    historyBtn:'更多紫江历史',
    aboutbtn:'了解集团详情',
    aboutView:'紫江，创始于1981年，是一家根植于上海的民营投资控股集团。<br />紫江之名，取自发源地附近一条“紫港”的江流，也寓意紫江将不断汇聚力量，迈向更广阔的发展空间。',
    home: '首页',
    about: '关于紫江',
    contact: '联系我们',
    buju:'产业布局',
    bujuView:'紫江的产业分为两大支柱板块——制造业与高新区。',
    jianjie:'紫江简介',
    jiazhi:'核心价值观',
    licheng:'发展历程',
    rencai:'人才战略',
    shehui:'社会责任',
    dang:'党工团',
    baozhuang:'饮料包装',
    ruanbao:'软包及新材料',
    qita:'其他类',
    yanfa:'研发基地',
    daxue:'大学校区',
    peitao:'配套区',
    chuangxin:'创新投资',
    zhuzai:'住宅开发',
    jiudian:'酒店业',
    bangong:'办公楼宇',
    guanyu:'关于紫江',
    zhizhao:'制造业',
    qitalei:'高新区',
    dichanye:'地产业',
    icp:'Copyright 2010 All rights reserved by Zijiang Holding    沪ICP备案号11028486号-1 ',
    dizhi:'上海市闵行区七莘路1388号',
    name: "姓名*",
    email: "邮箱*",
    mobile: "电话号码*",
    content: "请留下您的意见*"


    // 其他中文翻译
  }
};

const i18n = new VueI18n({
  locale: 'zh', // 默认语言
  messages,
});

export default i18n;