<template>
<div class="aboutCourse">
    <div class="container">
        <div class="about-nav">
            <ul class="nav" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="(tab, index) in tabs" :key="index">
                    <a class="addYearList" :class="{ active: activeTabAbout === index }" @click="selectTab(index)">
                        {{ tab.start_year }}-{{ tab.end_year }}
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="container-fluid containerBac">
        <div class="container">
            <div class="tab-content" id="myTabContent">

                <div>
                    <div v-for="(data, index) in DataView" :key="index" class="content-time">
                        <!-- 只显示第一个相同年份 -->
                        <div v-if="shouldShowYear(index)" class="event_time">
                            {{ getYear(data.event_time) }}
                            <div class="event_tag"></div>
                        </div>
                        <!-- <div class="title_cn" v-if="getYear(data.event_time) < 1981 || getYear(data.event_time) > 2019">
                            {{ getMonth(data.event_time) }}月
                        </div> -->
                        <div class="description_cn" v-html="currentLanguage === 'zh' ? data.content_cn : data.content_en">
                        </div>
                        <!-- {{ currentLanguage === 'zh' ? data.description_cn : data.description_en }} -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'aboutCourse',
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            activeTabAbout: 0,
            tabs: [], // 用于存储动态获取的标签数据
            loading: false,
            error: null,
            pageIndex: 0,
            pageSize: 20,
            DataView: []
        };
    },
    created() {
        this.fetchTabsData();
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        // 提取年份
        getYear(event_time) {
            return event_time.split('-')[0];
        },
        // 提取月份
        getMonth(event_time) {
            return event_time.split('-')[1];
        },
        // 检查是否显示年份
        shouldShowYear(index) {
            if (index === 0) return true; // 第一个数据总是显示年份
            const currentYear = this.getYear(this.DataView[index].event_time);
            const previousYear = this.getYear(this.DataView[index - 1].event_time);
            return currentYear !== previousYear; // 如果当前年份和上一个不一样，则显示
        },
        async fetchTabsData() {
            this.loading = true;
            try {
                const response = await this.$api.get('/api/history/year');
                this.tabs = response.data;
                this.selectTab(0); // 默认选中第一个
            } catch (error) {
                this.error = 'Failed to load tabs data';
            } finally {
                this.loading = false;
            }
        },
        async selectTab(tabId) {
            this.activeTabAbout = tabId;
            this.loading = true;
            this.error = null;

            try {
                const params = {
                    hy_id: this.tabs[tabId].hy_id,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    sort: 'DESC'
                };
                const response = await this.$api.get('/api/history/list', {
                    params
                });
                this.DataView = response.data.row;
            } catch (error) {
                this.error = 'Failed to load data';
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
/* 样式保留与现有的类似 */
</style><style scoped>
.presentation {}

.about-nav {
    padding: 20px 0;
}

.title_cn {
    padding-bottom: 5PX;
}

.addYearList {
    font-family: Impact;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    text-decoration: none;
    font-variation-settings: "opsz"auto;
    color: #D8D8D8;
    text-align: center;
}

.tab-content {
    padding: 30px 0;

}

.containerBac {
    background-color: #F5F5F5;
    background-image: url('@/assets/earthBacB.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.nav-item {
    flex: 1;
    text-align: center;
}

.active {
    font-size: 2.25rem;
    font-weight: bolder;
    border-bottom: 3px solid #8291C1 !important;
    padding-bottom: 5px;
    color: #5266A5;
}

.nav {
    align-items: flex-end;
}

.content-time {
    border-left: 1px solid #5266A5;
    padding: 0 0 30px 30px;
    position: relative;
}

.event_tag {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #F5F5F5;
    border: 3px solid #5266A5;
    position: absolute;
    left: -7px;
    top: 30px;
}

.event_time {
    font-family: Impact;
    font-size: 60px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #8291C1;
    padding-bottom: 1.88rem;
}

.title_cn {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #3D3D3D;
}

.description_cn {
    font-size: 16px;
    font-weight: normal;
    line-height: 2;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #3D3D3D;
}
</style>
