<template>
<div class="new-component">
    <header class="container container-add">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <router-link class="navbar-brand" to="/">
                    <img src="../assets/zjlogo.png" class="logo" alt="Logo">
                </router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav d-flex justify-content-end">
                        <li class="nav-item dropdown">
                            <router-link class="nav-link" :class="{ active: activeTab === 'home' }" to="/">{{ $t('home') }}</router-link>
                        </li>

                        <li class="nav-item dropdown" @mouseover="showDropdown2 = true" @mouseleave="showDropdown2 = false">
                            <router-link class="nav-link" :class="{ active: activeTab === 'about' }" :to="{ name: 'AboutView' }" @click.native="goToAbout">{{ $t('about') }}</router-link>

                            <ul v-show="showDropdown2" class="dropdown-menu show">
                                <li>
                                    <router-link class="dropdown-item" to="/about"> {{ $t('jianjie') }}</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" :to="{ path: '/about', hash: '#profile' }">{{ $t('jiazhi') }}</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/History">{{ $t('licheng') }}</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/ESG">{{ $t('shehui') }}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item" v-if="currentLanguage !== 'en'">
                            <router-link class="nav-link" :class="{ active: activeTab === 'news' }" to="/PressCenter">新闻中心</router-link>
                        </li>
                        <li class="nav-item dropdown" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
                            <!-- <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="/industry">{{ $t('buju') }}</router-link> -->
                            <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="">{{ $t('buju') }}</router-link>
                            <ul v-if="showDropdown" class="dropdown-menu show">

                                <li>
                                    <router-link class="dropdown-item" to="/appLayout"> {{ $t('zhizhao') }}</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/PageLayout">{{ $t('qitalei') }}</router-link>
                                </li>
                                <!-- <li>
                                    <router-link class="dropdown-item" to="/realEstate"> {{ $t('dichanye') }}</router-link>
                                </li> -->
                                <!-- <li>
                                    <router-link class="dropdown-item" to="/PageLayout"> {{ $t('peitao') }}</router-link>
                                </li>
                                 <li>
                                    <router-link class="dropdown-item" to="/PageLayout"> {{ $t('chuangxin') }}</router-link>
                                </li> -->
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :class="{ active: activeTab === 'contact' }" to="/ContactUs">{{ $t('contact') }}</router-link>
                        </li>
                    </ul>
                    <div class="d-flex Btn-left">
                        <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'zh' }" @click="switchLanguage('zh')">中</div>
                        <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'en' }" @click="switchLanguage('en')">EN</div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</div>
</template>

<script>
export default {
    name: 'NavBar',
    props: {
        activeTab: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            showDropdown: false, // 控制下拉菜单显示
            showDropdown2: false, // 控制下拉菜单显示
        };
    },
    methods: {
        switchLanguage(lang) {
            this.$i18n.locale = lang;
            this.currentLanguage = lang;
        },
        goToAbout(event) {
            if (this.$route.name !== 'AboutView') {
                window.open({
                    name: 'AboutView'
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            }
            event.preventDefault();
        },
    }
};
</script>

<style scoped>
#navbarNav {
    justify-content: end;
}

.navbar-nav {
    padding-right: 19px;
    border-right: 1px solid #d8d8d8;
}

.logo {
    width: 148px;
    height: 39px;
}

.bg-light {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.8) !important;
}

.new-component {
    width: 100%;
    height: 75px;
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 99;
}

.tabBtn {
    width: 24px;
    height: 24px;
    border: 1px solid #8291c1;
    color: #8291c1;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    margin: 0 5px;
}

.btnActive {
    background-color: #8291c1;
    color: #fff !important;
}

.active {
    font-weight: bold;
    border-bottom: 3px solid #8291c1;
}

.nav-item {
    font-size: 16px;
    color: #3d3d3d;
}

.Btn-left {
    padding-left: 19px;
}

.nav-item {
    padding: 0 12px;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #d8d8d8;
    padding: 0;
    z-index: 9999999;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #3d3d3d;
    text-align: left;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

@media (min-width: 1200px) {
    .container-add {
        max-width: 922px !important;
        margin: auto;
    }
}

@media (min-width: 992px) {
    .container-add {
        max-width: 922px !important;
        margin: auto;
    }
}
</style>
